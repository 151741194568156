import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const MiniPizzas = () => (
  <>
    <SEO title="Mini Pizzas" />

    <Menu className="menu">
      <Slide left cascade duration={500}>
        <h1 className="menu-title">Mini Pizzas</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">401 Mussarela</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Mussarela, Tomate, Molho de Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">402 Bauru</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Mussarela, Presunto, Tomate, Molho de Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">403 Calabresa</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">Calabresa, Cebola e Mussarela.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">404 Frango com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Frango, Catupiry, Mussarela, Molho de Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">405 Frango ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Frango, Catupiry, Milho, Bacon, Mussarela, Molho de Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">406 Portuguesa l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Presunto, Ovo, Azeitona Preta, Cebola, Mussarela, Molho de Tomate e
          Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">407 Portuguesa ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Presunto, Ovo, Azeitona Preta, Cebola, Catupiry, Mussarela, Molho de
          Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">408 Lombo Canadense</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Lombo Canadense, Mussarela, Molho de Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">410 Atum l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">Atum, Mussarela, Molho de Tomate e Orégano.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">411 Atum ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Atum, Cebola, Azeitona Preta, Mussarela, Molho de Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">412 Atum lll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Atum, Cebola, Azeitona Preta, Catupiry, Mussarela, Molho de Tomate e
          Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">413 Milho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Milho, Catupiry, Mussarela, Molho de Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">414 Palmito l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Palmito, Mussarela, Molho de Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">415 Palmito ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Palmito, Catupiry, Mussarela, Molho de Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">416 4 Queijos</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Mussarela, Gorgonzola, Provolone e Catupiry.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">418 Margherita</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Mussarela, Tomate, Manjericão e Molho de Tomate.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">419 Tomate Seco com Rúcula</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Tomate Seco, Azeitona Preta, Mussarela, Molho de Tomate e Orégano.
        </p>
      </Item>

      <Slide left cascade duration={500}>
        <h1 className="menu-title">Mini Pizzas Doces</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">420 Banana</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">Banana, Leite Moça, Canela.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">421 Brigadeiro</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">Nutella, Chocolate Flocos.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">422 Mesclada</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">Nutella e Chocolate Branco.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">423 Confetti</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">Nutella, M&Ms Confete.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">424 Banana com Chocolate</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">Banana com Nutella.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">425 Banana Especial</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">Banana, Nutella, Creme de Leite e Canela.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">426 Prestígio</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">Nutella, Coco Ralado e Leite Condensado.</p>
      </Item>
    </Menu>
  </>
);

export default MiniPizzas;
